<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Access Log</ion-title>
      </ion-toolbar>
    </ion-header>
   
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <ion-grid class="ricerca">
          <ion-row>
            <ion-col>
              <ion-label id="lutente">Utente:</ion-label>
              <ion-select id="ricutente"  v-model="search.user">
                <ion-select-option :value="c.email" v-for="c,i in users" :key="'consultant-'+i">{{c.email}}</ion-select-option>
              </ion-select> 
            </ion-col>
            <ion-col>
              <ion-label id="ldata">Data inizio:</ion-label>
              <ion-datetime id="ricdata" date-format="DD-MM-YYYY" display-format="DD-MM-YYYY" v-model="search.date"></ion-datetime>
            </ion-col>
            <ion-col>
              <ion-label id="ldata">Data fine:</ion-label>
              <ion-datetime id="ricdata" date-format="DD-MM-YYYY" display-format="DD-MM-YYYY" v-model="search.enddate"></ion-datetime>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label id="lip"> Indirizzo IP:</ion-label>
              <ion-input id="ricip" v-model="search.ipaddress"></ion-input>
            </ion-col>
            <ion-col>

            </ion-col>
            <ion-col>

            </ion-col>
          </ion-row>
          <ion-row id="buttons">
            <ion-button class="btncerca" @click="getLog()">
                RICERCA
            </ion-button>
            <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
              X
            </ion-button>
          </ion-row>
          </ion-grid>
          <ion-button id="export" @click="esportaExcel()" style="width:5%;margin:1%;margin-left:0%;--ion-color-primary:#2c912f">
            <ion-icon :icon="downloadOutline"></ion-icon>
          </ion-button>
        
          <dtable          
            color="#fff"
            bgcolor="rgb(56,128,255)"
            :heading="accessLogHeading"
            :data="logs"
            :total="table.total"
            :perPage="table.perPage"
            :currentPage="table.currentPage"
            :orderBy="table.orderBy"
            :order="table.order"
            @changePage="getLog"
          >
          </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonBackButton,
    IonButtons,
    IonButton,
    IonCol, 
    IonContent,
    IonDatetime,
    IonGrid, 
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel,
    IonMenuButton, 
    IonPage, 
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle, 
    IonToolbar 
  } from '@ionic/vue';
  import Dtable from './partials/Dtable'
  import {downloadOutline} from 'ionicons/icons';

  export default {
    name: 'Folder',
    components: {
        IonBackButton,
        IonButtons,
        IonButton,
        IonCol, 
        IonContent,
        IonDatetime,
        IonGrid, 
        IonHeader,
        IonIcon,
        IonInput,
        IonLabel,
        IonMenuButton, 
        IonPage, 
        IonRow,
        IonSelect,
        IonSelectOption,
        IonTitle, 
        IonToolbar,
        Dtable 
    },
    data: function(){
      return{
      logs:[],
      log:{
        userid:0,
        date:'',
        },
        accessLogHeading: [
          {
            text: 'Utente',
            code: 'usermail'
          },
          {
            text: 'Data',
            code: 'date'
          },
          {
            text: 'Indirizzo IP',
            code: 'ipaddress'
          },      
        ],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'date',
          order: 'desc',
        },
        search:{
          user:'',
          date:'',
          enddate:'',
          ipaddress:'',
        },
        users:[],
        downloadOutline:downloadOutline
      }
    },
    methods:{
      getLog(obj = false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-access-log', { page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search }, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.logs=response.data.logs.data
            this.table.total = response.data.logs.total
            this.table.perPage = response.data.logs.per_page            
          }
        })
      },
      getUsersList(){
        this.axios.post(this.apiUrl + 'get-users-list', {}, this.headers)
        .then(async (response) => {
          this.users=response.data.users;
        })
      },
      svuotaFiltro(){
        this.search.user='';
        this.search.date='';
        this.search.enddate=this.search.date;
        this.search.ipaddress='';
        this.getLog();
      },
      esportaExcel(){
        window.open('http://backend.hbj-group.com/download-excel-accesslog?ipaddress='+this.search.ipaddress+'&user='+this.search.user +'&date='+this.search.date +'&enddate='+this.search.enddate +'&token='+this.$store.state.apiToken,'_blank')
      },
      ionViewWillEnter: function(){
        this.getLog();
        this.getUsersList();
      }
    }
  }
</script>

<style scoped>
  #container{
    width:90%;
    margin:3%; 
  }
  .headercol,.content{
    background-color:#9ea8cc48;
    min-width:40px;
    min-height:60px;
  }
  .contenuto{
    width:80%;
    margin-left:10%;
    margin-top:5%;
    border-bottom-left-radius: 10px;
  }
  .header{
    background: darkblue;
    font-weight: bold;
    color:white;
    text-align:center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom:5px solid white;
  }
  .headercol{
    border-right:2px inset rgba(33, 209, 200, 0.479);
  }
  .content{
    border-right:2px inset rgba(33, 209, 200, 0.158);
    border-bottom:2px inset rgba(33, 209, 200, 0.158);
  }
  .ricerca{
    margin:10px;
    border:1px solid grey;
    border-radius:5px;
    margin-bottom:5%;
  }
  #ricutente,#ricdata,#ricip{
    border:1px solid rgb(184, 184, 184);
    border-radius:5px;
  }
  #lutente{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  #ldata{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  #lip{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  ion-button{
    width:100%;
  }
  .btncerca{
    width:92%;
  }
  .btnfiltro{
    width:5%;
  }
  #buttons{
    display:inline;
  }
  @media only screen and (max-width: 600px) {
    .btncerca{
      width:80%;
    }
    .btnfiltro{
      width:10%;
    }
    #buttons{
      display:inline;
    }
  }
</style>